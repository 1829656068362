#onetrust-pc-btn-handler {
  color: var(--text-color-primary) !important;
  background-color: var(--bg-body) !important;
  border: none !important;
  text-decoration: underline !important;
  width: fit-content !important;
  padding: 10px !important;
}

.ot-sdk-container {
  padding: 1rem !important;
}

#onetrust-pc-btn-handler:hover {
  text-decoration: none !important;
}

#onetrust-button-group {
  display: flex !important;
  gap: 1rem !important;
  margin: 0.5rem 0 0 !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}

#ot-sdk-btn-floating {
  display: none !important;
}

@media screen and (max-width: 896px) {
  #onetrust-button-group {
    justify-content: center !important;
  }
}

#onetrust-policy {
  margin-left: 0 !important;
}

.banner-actions-container {
  display: flex !important;
  gap: 1rem !important;
  flex-direction: column !important;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  display: inline-block !important;
  border-radius: var(--button-radius) !important;
  border-width: 0 !important;
  border-style: solid !important;
  border-color: var(--button-border-color) !important;
  font-size: var(--text-body-size) !important;
  font-weight: var(--text-button-weight) !important;
  letter-spacing: 1px !important;
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color) !important;
  outline-width: 1px !important;
  outline-style: solid !important;
  outline-color: var(--button-outline-color) !important;
  box-shadow: var(--badge-shadow) !important;
  height: fit-content;
  margin: 0 !important;
  padding: 10px !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  background-color: var(--bg-body) !important;
  color: var(--text-color) !important;
}

.ot-cookie-policy-link,
#onetrust-policy-text {
  color: var(--text-color) !important;
}

#onetrust-banner-sdk #onetrust-policy {
  margin-top: 0 !important;
}

#onetrust-banner-sdk #onetrust-button-group-parent {
  padding: 0 !important;
}

.banner-header {
  margin: 0 !important;
}
